import { locationId, name, title, slackAlerts } from '@agp/branches/specific/tempe'
import { SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, ALL_DAYS } from '@agp/constants/weekdays'
import { ROSH_HASHANAH_DAY_2, YOM_KIPPUR_EREV, SUKKOT_EREV, SUKKOT_DAY_1, SUKKOT_DAY_2, SUKKOT_DAY_6, SUKKOT_DAY_7 } from '@agp/constants/holidays-2025'
import { PROMO, MEMBER, VOUCHER } from '@agp/constants/calendar-types'
import { FACIAL, BIOREPEEL } from '@agp/constants/service-types'

export const LOCATION_ID = locationId
export const LOCATION_NAME = name
export const LOCATION_TITLE = title
export const COMPANY_NAME = "Dolce Medical Spa"
export const COMPANY_NAME_SHORT = "Dolce"
export const COMPANY_LOGO = "https://storage.googleapis.com/highlevel-backend.appspot.com/location/MXBnHb9E2unG15D0fqCL/workflow/f1e52408-dd6b-4b4d-a0b3-a9d091f663f5/9ee7aa57-2364-45db-ad9a-209de9121edb.png"
export const COMPANY_ADDRESS = "1615 E Warner Rd, Tempe, AZ 85284"
export const COMPANY_MAPS_LINK = "https://maps.app.goo.gl/u5a8xXf7dMVMeDbP7"
export const COMPANY_PHONE = "(602) 780-0526"
export const COMPANY_WHATSAPP = "https://wa.me/16027800526"
export const COMPANY_FBMSGER = "https://m.me/dolcemedicalspatempe"
export const REVIEW_RATING = 4.9
export const REVIEW_COUNT = 199
export const TIMEZONE = "America/Phoenix"

// passwords
export const MANAGER_PASSWORD = "az3$2ua81!"
export const STAFF_PASSWORD = "Dolce85284$"

// external API keys and IDs
export const POSTHOG_APP_ID = "phc_mjka8zYbHCIcPMQHQ1ji9fIJ2JGHMxFMaObUFzPDHwI"
export const SLACK_NOTIFICATION_WEBHOOK = slackAlerts
export const FB_PIXEL = "825431026268190"

// location-specific config
export const TREATMENT_ROOMS = 2
export const HOURS_OF_OPERATION_START = 10 // 10am
export const HOURS_OF_OPERATION_END = 19 // 7pm
export const LUNCH_HOUR = 14 // 2pm
export const APPOINTMENT_DURATION_MINS = 60
export const APPOINTMENT_BUFFER_MINS = 0
export const FUTURE_AVAILABLE_DAYS = 7
export const OPERATION_OVERRIDES = {
	[FRIDAY]: { end: 16 },

	[ROSH_HASHANAH_DAY_2]: { 
		[PROMO]: { open: false },
	},
	[YOM_KIPPUR_EREV]: {
		[PROMO]: { end: 15 },
		[MEMBER]: { end: 17 },
	},
	[SUKKOT_EREV]: {
		[PROMO]: { end: 16 },
	},
	[SUKKOT_DAY_1]: {
		[PROMO]: { open: false },
	},
	[SUKKOT_DAY_2]: {
		[PROMO]: { open: false },
	},
	[SUKKOT_DAY_6]: {
		[PROMO]: { end: 16 },
	},
	[SUKKOT_DAY_7]: {
		[PROMO]: { open: false },
	},
	['2024-10-25']: {
		[PROMO]: { open: false },
	},
}

// calendar config
export const CALENDARS = [
	{
		type: PROMO,
		service: FACIAL,
		id: "Fe83EyF5Ul045MV3uUnm",
		name: "Medical Facial Promo",
		description: "Discover the unique concept of Medical Facials; using medical-grade products and protocols; created by dermatologists.",
		public: true,
		promoPrice: 79,
		originalPrice: 229,
	},
	{
		type: VOUCHER,
		service: FACIAL,
		id: "DIF3ZjvAjKT0ajm6tAFH",
		name: "Medical Facial (Voucher)",
	},
	{
		type: MEMBER,
		service: FACIAL,
		id: "pm3Kn5yOxTGKac07qm8m",
		name: "Medical Facial (Members)",
	},
	{
		type: PROMO,
		service: BIOREPEEL,
		id: "zQ24fSgcZG9kauEAorQ3",
		name: "BioRePeel Promo",
		description: "FDA-Approved, BioRePeel works almost like magic with 0 downtime and no pain at all",
		public: true,
		promoPrice: 99,
		originalPrice: 299,
	},
]